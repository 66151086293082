<script setup lang="ts">

const route = useRoute();
const eventId = computed(() => Number(route.params.eventid));
const organizationId = computed(() => Number(route.params.organizationid));

const links = computed(() => { 
  const prefix = `/host/${organizationId.value}/${eventId.value}/`;
  return [
  {
    label: "Home",
    icon: "i-heroicons-home",
    to: prefix,
  },
  {
    label: "Attendee List",
    icon: "i-heroicons-list-bullet",
    to: `${prefix}attendee-list`,
  },
  {
    label: "Redeem Pass",
    icon: "i-heroicons-ticket",
    to: `${prefix}redeem-pass`,
  },
  ]
});

</script>

<template>
  <div v-if="eventId && organizationId" class="flex flex-col gap-4">
    <u-input
      icon="i-heroicons-magnifying-glass-20-solid"
      size="sm"
      color="white"
      :trailing="false"
      placeholder="Search..."
    />

    <u-vertical-navigation :links="links" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const eventId = ref(Number(route.params.eventid));
const organizationId = ref(Number(route.params.organizationid));

const { data: events, isLoading, isError } = useContool("/v1/organization/{id}/event", "get", {
  params: {
    id: organizationId.value,
  },
});

const selectedEvent = computed(() => {
  if (!events.value) {
    return null;
  }

  return events.value.find(({ id }) => id === eventId.value);
});

const eventDropdownList = computed(() => {
  let result = [];

  if (isLoading.value) {
    result = [{ id: 0, label: 'Loading...' }];
    return [result];
  }

  if (isError.value) {
    result = [{ id: 0, label: 'Error fetching events' }];
    return [result];
  }

  result = events.value?.map(({ id, title }) => ({
    id,
    label: title,
    slot: id === eventId.value ? "activeEvent" : "event",
  })) || [];

  return [result];
});

const selectEvent = (newId: number) => navigateTo(`/host/${organizationId.value}/${newId}`);

watch(
  () => route.params.organizationid,
  (newId) => {
    organizationId.value = +(newId as string);
  },
);

watch(
  () => route.params.eventid,
  (newId) => {
    eventId.value = +(newId as string);
  },
);

</script>

<template>
  <u-dropdown :items="eventDropdownList" :popper="{ placement: 'bottom-start' }" :ui="{
    width: 'w-72',
  }">
    <u-button v-if="selectedEvent" color="gray" variant="solid" trailing-icon="i-heroicons-chevron-down-20-solid"
      class="w-full">
      <span class="truncate w-full text-start">{{ selectedEvent.title }}</span>
    </u-button>

    <u-button v-else color="gray" variant="solid" trailing-icon="i-heroicons-chevron-down-20-solid" class="w-full">
      <span class="truncate w-full text-start">Select an Event</span>
    </u-button>

    <template #activeOrg="{ item }">
      <u-avatar :src="item.icon" size="2xs" :alt="item.label" />
      <span class="truncate w-full text-start">{{ item.label }}</span>
      <u-icon name="i-heroicons-check-20-solid" class="text-lg" />
    </template>

    <template #event="{ item }">
      <div class="flex gap-2 items-center" @click="selectEvent(item.id)">
        <u-avatar :src="item.icon" size="2xs" :alt="item.label" />
        <span class="truncate w-full text-start">{{ item.label }}</span>
      </div>
    </template>
    
  </u-dropdown>
</template>

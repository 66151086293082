<script setup lang="ts">
import Sidebar from "@/components/host/Sidebar.vue";
import CurrentUserMenu from "~/components/host/CurrentUserMenu.vue";

const { status } = useSession(true);
const route = useRoute();
const showMobileSidebar = ref(false);

watch(
  () => route.fullPath,
  () => {
    showMobileSidebar.value = false;
  },
);
</script>

<template>
  <div class="fixed inset-0 flex overflow-hidden">
    <div class="w-72 p-2 border-e flex-col gap-4 hidden lg:flex">
      <branding />
      <host-organization-selector />
      <u-divider />
      <sidebar class="h-full" />
      <current-user-menu v-if="status === SessionStatus.AUTHENTICATED" />
    </div>

    <div class="w-full">
      <div class="w-full p-4 flex gap-4 items-center justify-between">
        <u-button
          icon="i-heroicons-bars-3"
          size="sm"
          color="white"
          square
          variant="ghost"
          class="lg:hidden"
          @click="showMobileSidebar = true"
        />

        <branding class="lg:hidden" />

        <div class="hidden lg:block" />

        <div class="flex items-center gap-2">
          <u-chip inset text="3" size="xl">
            <u-button
              icon="i-heroicons-bell"
              size="sm"
              color="white"
              square
              variant="ghost"
            />
          </u-chip>
        </div>
      </div>

      <div class="h-full pb-12 overflow-scroll px-4 space-y-4">
        <slot />
      </div>
    </div>

    <!-- Mobile sidebar -->
    <div
      class="w-72 p-4 border-e flex-col gap-4 flex fixed z-50 h-dvh duration-200 bg-slate-50 transition"
      :class="{ '-translate-x-72': !showMobileSidebar }"
    >
      <branding />
      <host-organization-selector />
      <u-divider />
      <sidebar class="h-full" />
      <current-user-menu v-if="status === SessionStatus.AUTHENTICATED" />
    </div>

    <div
      v-if="showMobileSidebar"
      class="fixed inset-0 bg-black opacity-50"
      @click="showMobileSidebar = false"
    />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const organizationId = ref(+(route.params.organizationid as string));

const { data: organizations, suspense } = useContool(
  "/v1/user/{id}/organization",
  "get",
  {
    params: {
      id: "me",
    },
  },
);

await suspense;

const selectedOrganization = computed(() => {
  if (!organizations.value) {
    return null;
  }

  return organizations.value.find(({ id }) => id === organizationId.value);
});

const organizationDropdownList = computed(() => [
  organizations.value?.map(({ id, logo, name }) => {
    return {
      id,
      icon: logo,
      label: name,
      slot: id === organizationId.value ? "activeOrg" : "organization",
    };
  }) || [],
]);

const selectOrganization = (newId: number) => navigateTo(`/host/${newId}`);

watch(
  organizations,
  () => {
    if (!organizations.value) {
      return;
    }
    if (organizations.value.length === 0) {
      throw createError({
        statusCode: 403,
        statusMessage: "User is not allowed to access",
      });
    }
  },
  { immediate: true },
);

watch(
  () => route.params.organizationid,
  (newId) => {
    organizationId.value = +(newId as string);
  },
);
</script>

<template>
  <u-dropdown
    :items="organizationDropdownList"
    :popper="{ placement: 'bottom-start' }"
    :ui="{
      width: 'w-72',
    }"
  >
    <u-button
      v-if="selectedOrganization"
      color="gray"
      variant="solid"
      trailing-icon="i-heroicons-chevron-down-20-solid"
      class="w-full"
    >
      <template #leading>
        <u-avatar
          :alt="selectedOrganization.name"
          :src="selectedOrganization.logo"
          size="xs"
        />
      </template>
      <span class="truncate w-full text-start">{{
        selectedOrganization.name
      }}</span>
    </u-button>

    <u-button
      v-else
      color="gray"
      variant="solid"
      trailing-icon="i-heroicons-chevron-down-20-solid"
      class="w-full"
    >
      <span class="truncate w-full text-start">Select an Organization</span>
    </u-button>

    <template #activeOrg="{ item }">
      <u-avatar :src="item.icon" size="2xs" :alt="item.label" />
      <span class="truncate w-full text-start">{{ item.label }}</span>
      <u-icon name="i-heroicons-check-20-solid" class="text-lg" />
    </template>

    <template #organization="{ item }">
      <div
        class="flex gap-2 items-center"
        @click="() => selectOrganization(item.id)"
      >
        <u-avatar :src="item.icon" size="2xs" :alt="item.label" />
        <span class="truncate w-full text-start">{{ item.label }}</span>
      </div>
    </template>
  </u-dropdown>

  <host-event-selector v-if="selectedOrganization" />
</template>
